import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const OpenStreetMap = ({ height, zoom }) => {
  return (
    <div style={{ height, width: "100%" }}>
      <MapContainer
        style={{ height: "100%" }}
        zoom={zoom}
        center={[45, -55]}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[52.2895611, -1.5326275]}>
          <Popup>34 Hamilton Terrace, Leamington Spa, CV32 4LY</Popup>
        </Marker>
        <Marker position={[51.9107865, -0.3883582]}>
          <Popup>Basepoint, Great Marlings, Luton, LU2 8DL</Popup>
        </Marker>
        <Marker position={[30.3432471, -81.656336]}>
          <Popup>Suite 3159, 1601-1 N Main St, Jacksonville, FL 32206</Popup>
        </Marker>
        <Marker position={[49.2870573, -123.1239857]}>
          <Popup>Suite 501, 535 Thurlow St, Vancouver, BC, V6E 3L2</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default OpenStreetMap;
