import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import GeneralInquiries from "../assets/general-inquiries.svg";
import Sales from "../assets/sales.svg";
import CAMOServices from "../assets/camo-services.svg";
import CustomerSupport from "../assets/tech-support.svg";
import ContactCard from "../components/Contact/contactCard";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./contact.module.less";
import Started from "../components/Layout/started";
import OpenStreetMap from "../components/Contact/openStreetMap";

const ContactDepartment = styled.div`
  margin-bottom: 60px;
  h2 {
    margin: 0;
    font-family: "Open Sans";
    color: #242d41;
    letter-spacing: 0.04px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
  }
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const phoneNumbers = [
  {
    phone: "+18447849836",
    phoneText: "US: +1 (844) 784-9836",
  },
  {
    phone: "+443302290219",
    phoneText: "UK: +44 (0) 330 229 0219 \n",
  },
  {
    phone: "+18447849836",
    phoneText: "CAN: +1 (844) 784-9836",
  },
];

const general = {
  img: GeneralInquiries,
  title: "General Inquiries",
  text: "Have a general query? We'd love to hear from you.",
  email: "info@trustflight.com",
  phoneNumbers,
};

const sales = {
  img: Sales,
  title: "Sales",
  text: "Enquire about our products or services.",
  email: "sales@trustflight.com",
  phoneNumbers,
};

const camo = {
  img: CAMOServices,
  title: "Continuing Airworthiness Services",
  text: "Find out how you can benefit from our services.",
  email: "info.camo@trustflight.com",
  phoneNumbers,
};

const support = {
  img: CustomerSupport,
  title: "Customer Support",
  text: "Need a hand? Get in touch with the TrustFlight support team.",
  email: "support@trustflight.com",
  phoneNumbers,
};

const contactArray = [general, sales, camo, support];

const cards = contactArray.map((card, key) => (
  <Col md={6} lg={3} className={`${styles.contactCard} ${pageStyles.coliPad}`}>
    <AnimationOnScroll
      animateOnce
      animateIn="animate__fadeIn"
      offset={0}
      delay={key * 75}
    >
      <ContactCard
        key={card.title}
        img={card.img}
        title={card.title}
        text={card.text}
        email={card.email}
        phoneNumbers={card.phoneNumbers}
      />
    </AnimationOnScroll>
  </Col>
));

const Title = styled.span`
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin: 30px 0;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const TitleWrapper = styled.div`
  padding: 30px 0;
  padding-bottom: 20px;
`;

const AddressTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
`;

const AddressSubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 5px;
`;

const AddressDivider = styled.div`
  display: block;
  width: 100%;
  height: 30px;
  ${(props) => props.big && "height: 75px;"}
  ${(props) => props.small && "height: 20px;"}
  @media screen and (max-width: 768px) {
    ${(props) => props.big && "height: 30px;"}
    ${(props) => props.small && "height: 30px;"}
  }
`;

const AddressText = styled.p`
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 15px;
  font-size: 16px;
`;

const StyledButton = styled.button`
  margin: 0px 8px;
  padding: 9px 20px;
  border-radius: 25px;
  color: #242d41;
  font-size: 16px;
  cursor: pointer;
  border: 1px #dcdfe2 solid;
  transition: all 0.5s;
  background: #fff;
  ${(props) =>
    props.active && "background: #1E5BB5; color: #fff; border-color: #1E5BB5;"}
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    outline: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

const ButtonsHolder = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
`;

const AddressWrapper = styled.div`
  margin: 15px 0;
`;

const FormOffsetContainer = styled.div`
  // hack to get rid of the masssive spacing at top of form it uses on some other pages.
  @media screen and (min-width: 1200px) {
    margin-top: -120px;
    min-height: 800px;
  }
  width: 100%;
  position: relative;
`;

export default class contact extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      activeForm: "question",
    };
  }

  setForm = (form) => {
    this.setState({
      activeForm: form,
    });
  };

  render() {
    const { activeForm } = this.state;
    return (
      <Layout>
        <Seo
          title="Get in touch"
          description="Our team is happy to answer any questions you might have."
        />
        <PageHeader
          header="Get in touch"
          subHeader="Our team is happy to answer any questions you might have."
        />
        <GradientSection>
          <Container>
            <Row
              className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
            >
              <Col md={11}>
                <ContactDepartment>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInDown"
                    offset={0}
                  >
                    <h2>Contact a specific department</h2>
                  </AnimationOnScroll>
                  <Row>{cards}</Row>
                </ContactDepartment>
              </Col>
            </Row>
          </Container>
        </GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
            name="formArea"
          >
            <Col md={11}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInUp"
                offset={0}
              >
                <Title>Or let us know how we can help you today.</Title>
              </AnimationOnScroll>
              <ButtonsHolder>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <StyledButton
                    onClick={() =>
                      this.setForm(
                        activeForm === "question" ? null : "question"
                      )
                    }
                    active={activeForm === "question"}
                  >
                    I have a question
                  </StyledButton>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                  delay={150}
                >
                  <StyledButton
                    onClick={() =>
                      this.setForm(activeForm === "demo" ? null : "demo")
                    }
                    active={activeForm === "demo"}
                  >
                    I would like to schedule a demo or call
                  </StyledButton>
                </AnimationOnScroll>
              </ButtonsHolder>
            </Col>
            <Col md={11}>
              {activeForm === "question" && (
                <FormOffsetContainer>
                  <Started
                    title="Talk to Support"
                    text="Our support teams are standing by to assist, 24/7."
                    buttonText="Ready to become data-driven?"
                    hubSpotFormId="d493e585-a9ff-4f22-9ded-012c24910154"
                  />
                </FormOffsetContainer>
              )}
            </Col>
            <Col md={11}>
              {activeForm === "demo" && (
                <FormOffsetContainer>
                  <Started
                    title="Connect with Sales"
                    text="Interested in exploring our solutions? Let's connect."
                    buttonText="Ready to become data-driven?"
                    hubSpotFormId="84d074da-6b26-4825-83e8-6a28d06386f2"
                  />
                </FormOffsetContainer>
              )}
            </Col>
          </Row>

          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRowMedium}`}
          >
            <Col md={12} className={styles.locationsCol}>
              <Row>
                <Col md={12}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                  >
                    <TitleWrapper>
                      <Title>Our Locations</Title>
                    </TitleWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col
                  md={12}
                  className={`${styles.desktopMap} ${pageStyles.coliPad}`}
                >
                  <AnimationOnScroll
                    animateOnce
                    animateIn="fade__in"
                    offset={0}
                  >
                    <OpenStreetMap height="300px" zoom={3} />
                  </AnimationOnScroll>
                </Col>
                <Col
                  md={12}
                  className={`${styles.locationText} ${pageStyles.coliPad}`}
                >
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                  >
                    <Row>
                      <Col
                        md={12}
                        className={`${styles.mobileMap} ${pageStyles.coliPad}`}
                      >
                        <OpenStreetMap height="200px" zoom={1} />
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>England</AddressTitle>
                          <AddressSubTitle>TrustFlight</AddressSubTitle>
                          <AddressText>
                            34 Hamilton Terrace, <br />
                            Leamington Spa, CV32 4LY
                          </AddressText>
                          <AddressDivider small />
                          <AddressSubTitle>TrustFlight CAMO</AddressSubTitle>
                          <AddressText>
                            Basepoint, Great Marlings, <br />
                            Luton, LU2 8DL
                          </AddressText>
                          <AddressDivider small />
                        </AddressWrapper>
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>USA</AddressTitle>
                          <AddressSubTitle>
                            TrustFlight Jacksonville
                          </AddressSubTitle>
                          <AddressText>
                            Suite 3159, 1601-1 N Main St, <br />
                            Jacksonville, FL 32206
                          </AddressText>
                        </AddressWrapper>
                      </Col>
                      <Col md={4}>
                        <AddressWrapper>
                          <AddressTitle>Canada</AddressTitle>
                          <AddressSubTitle>
                            TrustFlight Vancouver
                          </AddressSubTitle>
                          <AddressText>
                            Suite 501, 535 Thurlow St, <br />
                            Vancouver, BC, V6E 3L2
                          </AddressText>
                        </AddressWrapper>
                      </Col>
                    </Row>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
